<template>
  <div>
    <v-dialog v-model="show_dialog" persistent scrollable width="550">
      <v-card class="border-radius box-shadow">
        <v-card-title class="body-1 d-flex primary">
          <div class="white--text text-capitalize">
            Add User Company
          </div>
          <v-spacer></v-spacer>
          <v-icon @click="hideDialog()" color="white">mdi-close-circle</v-icon>
        </v-card-title>
        <v-card-text class="pt-4">
          <v-form ref="formAddUser">
            <v-row>
              <v-col cols="12">
                <v-text-field label="User Fullname" outlined dense color="primary" hide-details="auto"
                  :rules="rules.input" v-model="form.fullname">
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field label="Email" outlined dense color="primary" hide-details="auto"
                  :rules="[...rules.input, ...rules.email]" v-model="form.email">
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field label="Phone Number" outlined dense type="number" color="primary" hide-details="auto"
                  :rules="rules.input" v-model="form.phone_number">
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <p class="mb-0">Gender</p>
                <v-radio-group row class="mt-2" hide-details="auto" :rules="rules.input" v-model="form.gender">
                  <v-radio color="primary" :value="1">
                    <template v-slot:label>
                      <div class="black--text">Laki - laki</div>
                    </template>
                  </v-radio>
                  <v-radio color="primary" :value="0">
                    <template v-slot:label>
                      <div class="black--text">Perempuan</div>
                    </template>
                  </v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12">
                <p class="mb-0">User Status</p>
                <v-radio-group row class="mt-2" hide-details="auto" :rules="rules.input" v-model="form.status">
                  <v-radio color="primary" value="active">
                    <template v-slot:label>
                      <div class="black--text">Aktif</div>
                    </template>
                  </v-radio>
                  <v-radio color="primary" value="inactive">
                    <template v-slot:label>
                      <div class="black--text">Tidak aktif</div>
                    </template>
                  </v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12">
                <v-text-field label="Password" outlined dense color="primary" autocomplete="off" hide-details="auto"
                  :type="show_password_one ? 'text' : 'password'"
                  :append-icon="show_password_one ? 'mdi-eye-off' : 'mdi-eye'" :rules="rules.password"
                  :error-messages="error_message.password ? error_message.password : ''" v-model="form.password"
                  @click:append="show_password_one = !show_password_one">
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field label="Confirm Password" outlined dense color="primary" autocomplete="off"
                  hide-details="auto" :type="show_password_two ? 'text' : 'password'"
                  :append-icon="show_password_two ? 'mdi-eye-off' : 'mdi-eye'" :rules="rules.password"
                  :error-messages="error_message.confirm_password ? error_message.confirm_password : '' || check_confirm_password"
                  v-model="form.confirm_password" @click:append="show_password_two = !show_password_two"
                  @change="checkConfirmPassword">
                </v-text-field>
              </v-col>
              <v-col cols="12">
                <v-select v-model="form.role_id" filled item-text="label" item-value="value" label="Role" :items="roleSelect"></v-select>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="py-3">
          <v-spacer></v-spacer>
          <v-btn color="grey lighten-2" class="mr-1 text-capitalize" depressed width="120" height="40"
            :loading="btn_loading" @click="hideDialog()">
            Tutup
          </v-btn>
          <v-btn color="primary" class="white--text text-capitalize" depressed width="120" height="40"
            :loading="btn_loading" @click="saveUserCompany()">
            Add User
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <Snackbar ref="snackbar"></Snackbar>
  </div>
</template>

<script>
import { post, get } from "@/service/Axios";
import Snackbar from '@/components/Snackbar.vue';

export default {
  components: { Snackbar },
  data() {
    return {
      show_dialog: false,
      show_password_one: false,
      show_password_two: false,
      btn_loading: false,
      rules: {
        input: [v => !!v || 'This field is required'],
        email: [v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid'],
        password: [
          v => !!v || 'This field is required',
          v => (v && v.length >= 7) || "Password can't less than 7 characters",
        ],
      },
      form: {
        fullname: "",
        email: "",
        phone_number: "",
        gender: "",
        status: "",
        password: "",
        confirm_password: "",
        role_id: ""
      },
      error_message: {},
      check_confirm_password: '',
      roles: {
        list: []
      }
    }
  },
  props: {
    company_id: String,
  },
  async mounted() {
    this.roles = await this.getRoles()
  },
  computed: {
    roleSelect() {
      return this.roles.list.map((item) => {
        return {
          label: item.name,
          value: item.id
        }
      })
    }
  },
  methods: {
    async getRoles() {
      const { data } = await get('users/v1/superuser/role/list', {
        query: {
          company_id: this.company_id,
        }
      })
      return data?.data
    },
    showDialog() {
      this.show_dialog = true
    },
    hideDialog() {
      this.show_dialog = false
      this.btn_loading = false
      this.$refs.formAddUser.reset()
      this.$refs.formAddUser.resetValidation()
      this.error_message = {}
    },
    checkConfirmPassword() {
      if (this.form.confirm_password != this.form.password) {
        this.check_confirm_password = "Confirm Password different with Password"
      } else {
        this.check_confirm_password = ""
      }
    },
    async saveUserCompany() {
      if (this.$refs.formAddUser.validate()) {
        this.btn_loading = true
        await post(`users/v1/superuser/company_user/create`, {
          data: {
            company_id: this.company_id,
            fullname: this.form.fullname,
            email: this.form.email,
            phone_number: this.form.phone_number,
            gender: this.form.gender,
            status: this.form.status,
            password: this.form.password,
            confirm_password: this.form.confirm_password,
            role_id: this.form.role_id
          }
        }).then((response) => {
          let res = response.data
          if (res.status == 200) {
            this.hideDialog()
            this.$refs.snackbar.open("#4CAF50", `Add User Company Successfully`);
            this.$emit('action')
          } else {
            this.hideDialog()
            this.$refs.snackbar.open("#e53935", `Add User Company Failed`);
          }
        }).catch(() => {
          this.error_message = this.$store.state.ContentValidation
          this.btn_loading = false
        })
      }
    },
  },
};
</script>
